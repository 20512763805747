<template>
	<div>
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link :to="{name: 'roles-index'}">Roles</router-link>
				</li>
				<li class="breadcrumb-item active" v-if="model">
					<router-link :to="{name: 'roles-edit-id', params: { id: model.Id }}"
								 v-text="model.Name"></router-link>
				</li>
			</ol>
		</nav>
		<div v-if="model" class="pb-5">
			<role-form :disabled="!canWrite('roles')" v-model="model" @submit="save"></role-form>
		</div>
	</div>
</template>

<script>
	import api from '@/api'
	import messageBox from '@/components/modals/alert'
	import RoleForm from "../../../components/roles/role-form";
	import PermissionsEditor from "../../../components/permissions/permissions-editor";

	export default {
		data() {
			return {
				model: null
			}
		},
		mounted() {
			this.load(this.$route.params.id)
		},
		methods: {
			async load(user_id) {
				this.model = await api.Roles.get(user_id)

				if (this.model.Permissions === null) {
					this.model.Permissions = {}
				}

			},
			async save() {
				this.model = await api.Roles.update(this.model.Id, this.model)

				messageBox({
					message: `Role updated!`
				})
			}
		},
		components: {
			PermissionsEditor,
			RoleForm
		}
	}
</script>
<style scoped>

</style>
